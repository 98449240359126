import { setupNextraPage } from 'nextra/setup-page'

/*@jsxRuntime automatic @jsxImportSource react*/
import {useMDXComponents as _provideComponents} from "nextra/mdx";
import {Home} from '@/components/common';
export const __toc = [];
function _createMdxContent(props) {
  return <Home />;
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? <MDXLayout {...props}><_createMdxContent {...props} /></MDXLayout> : _createMdxContent(props);
}



const __nextraPageOptions = {
  MDXContent,
  pageOpts: {"filePath":"pages/index.mdx","route":"/","frontMatter":{"title":"Playlolly","description":"With our resources, you have everything you need for a realistic EV vehicle experience, right on your FiveM server.","ogImage":"og-image.jpg"},"title":"Playlolly",headings:__toc},
  pageNextRoute: "/",
  
}
if (process.env.NODE_ENV !== 'production') {
  __nextraPageOptions.hot = module.hot
  __nextraPageOptions.pageOptsChecksum = ''
}
if (typeof window === 'undefined') __nextraPageOptions.dynamicMetaModules = []

export default setupNextraPage(__nextraPageOptions)